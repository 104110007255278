<style lang="scss">
    .upload-doc {
        padding: 20px;
        background: #fff !important;

        overflow: auto;

        .ts-upload {
            display: flex;
            .el-button {
                margin-right: 15px;
            }
            ul {
                display: none;
            }
        }

        .el-descriptions {
            border-left: 1px solid #EBEEF5;
            border-bottom: 1px solid #EBEEF5;
        }
        .el-descriptions-row {
            display: flex;
        }
        .el-descriptions-item__label {
            width: 163px;
            color: #000 !important;
            text-align: center !important;
            border-left: 0 !important;
            border-right: 0 !important;
            padding: 0 !important;
            line-height: 42px !important;
            font-size: 14px !important;
            border-bottom: 0 !important
        }
        .el-descriptions-item__content {
            flex: 1;
            padding: 0 20px !important;
            line-height: 42px !important;
            border-bottom: 0 !important;

            & > * {
                width: 100%;
            }
            input {
                border: 0;
            }
        }
    }
</style>

<template>
    <div class="common-table" style="overflow: auto;">
        <div class="common-table__title">上传文献</div>

        <div class="upload-doc ts-table flex flex-item flex-column">
            <el-descriptions :column="3" border>
                <el-descriptions-item label="标题">
                    <el-input v-model="form.title" placeholder="请输入标题"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="单位机构">
                    <el-input v-model="form.organization" placeholder="请输入单位机构"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="发布日期/年份">
                    <el-input v-model="form.publishDate" placeholder="请输入发布日期/年份"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="作者">
                    <el-input v-model="form.author" placeholder="请输入作者"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="关键字/版本">
                    <el-input v-model="form.articleKeyword" placeholder="请输入关键字/版本"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="科室">
                    <el-input v-model="form.department" placeholder="请输入科室"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="病症">
                    <el-input v-model="form.disease" placeholder="请输入病症"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="诊疗方法" >
                    <el-input v-model="form.diagnosisMethod" placeholder="请输入诊疗方法"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="诊断要点">
                    <el-input v-model="form.diagnosisPoint" placeholder="请输入诊断要点"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="结论">
                    <el-input v-model="form.conclusion" placeholder="请输入结论"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="PDF文件">
                    <div class="ts-upload">
                        <el-upload ref="upload" :action="action" :auto-upload="false" :multiple="false" :show-file-list="false"
                                   :data="postData" :on-change="handleChange" :on-success="success" :on-error="failed"
                                   :before-upload="beforeUpload">
                            <el-button class="btn-blue" :loading="fileLoading">{{fileLoading ? '上传中...' : '上传文件'}}</el-button>
                        </el-upload>
                        <el-link type="primary" :underline="false" target="_blank" :href="form.fileUrl" v-show="form.fileUrl">点击查看</el-link>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="文章类别">
                    <el-select v-model="type">
                        <el-option value="baseArticleLiterature/insertBaseArticleLiterature" label="临床文献"></el-option>
                        <el-option value="baseArticleGuide/insertBaseArticleGuide" label="临床指南与共识"></el-option>
                        <el-option value="baseArticlePath/insertBaseArticlePath" label="临床路径"></el-option>
                    </el-select>
                </el-descriptions-item>
                <el-descriptions-item label=药品>
                    <el-select v-model="comNameList" :loading="drugs.loading" filterable :filter-method="drugSearch" multiple reserve-keyword value-key="comCode">
                        <el-option v-for="item in drugs.list" :key="item.comCode" :value="item" :label="item.comName"></el-option>
                    </el-select>
                </el-descriptions-item>
                <!--<el-descriptions-item label="文章国别"><input v-model="form.title"></input></el-descriptions-item>-->
            </el-descriptions>

            <div style="margin-right: 10px;">
                <ts-ueditor ref="ue" style="margin-top: 20px;"></ts-ueditor>
            </div>

            <div style="margin-top: 30px;">
                <el-button type="primary" style="padding: 15px 50px;" size="large" :loading="saveLoading" @click="save">确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import qiniu from '@/util/qiniu'
    export default {
        data() {
            return {
                type: 'baseArticleLiterature/insertBaseArticleLiterature',
                form: {
                    title: '',
                    organization: '',
                    publishDate: '',
                    author: '',
                    articleKeyword: '',
                    department: '',
                    disease: '',
                    diagnosisMethod: '',
                    diagnosisPoint: '',
                    conclusion: '',
                    products: '',
                    fileUrl: ''
                },

                action: qiniu.upload,
                drugs: {
                    list: [],
                    loading: false,
                },

                postData: {
                    token: '',
                    key: '',
                },
                fileLoading: false,
                fileList: [],
                comNameList: [],

                saveLoading: false,
            }
        },
        created() {
            this.drugSearch()
            qiniu.getToken().then(res => {
                if(res.success){
                    this.postData.token = res.data
                }
            })
        },
        methods: {
            drugSearch(keyword) {
                this.drugs.loading = true
                this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', {keyword: keyword})
                    .then(res => {
                        this.drugs.list = res.data
                    })
                    .finally(() => {
                        this.drugs.loading = false
                    })
            },
            handleChange() {
                if(this.read || this.fileLoading) {
                    return
                }
                this.fileLoading = true
                this.$refs.upload.submit();
            },
            beforeUpload(file) {
                const name = file.name
                const splitStart = name.lastIndexOf('.')

                this.postData.key = `${name.substr(0, splitStart)}-${new Date().Format('yyyyMMddhhmmss')}${name.substr(splitStart)}`
            },
            success(obj) {
                this.form.fileUrl = obj.data.url
                setTimeout(() => {
                    this.fileLoading = false
                }, 300)
            },
            failed(obj) {
                console.log(obj)
                setTimeout(() => {
                    this.fileLoading = false
                }, 300)
            },

            save() { 
                //sys/baseArticleLiterature/insertBaseArticleLiterature  临床文献文档
                //sys/baseArticleGuide/insertBaseArticleGuide 临床诊疗指南文档
                //sys/baseArticlePath/insertBaseArticlePath 临床诊疗指南文档
                const form = this.$help.deepClone(this.form)
                form.contentText = this.$refs.ue.get()
                if (!form.title) {
                    this.$store.commit('error', '请输入标题')
                    return false
                }
                if (this.comNameList.length === 0) {
                    this.$store.commit('error', '请选择药品')
                    return false
                }
                if(this.type === 'baseArticleLiterature/insertBaseArticleLiterature'){
                    form.baseArticleLiteratureItemDTOList = this.comNameList
                }
                if(this.type === 'baseArticleGuide/insertBaseArticleGuide'){
                    form.baseArticleGuideItemDTOList = this.comNameList
                }
                if(this.type === 'baseArticlePath/insertBaseArticlePath'){
                    form.baseArticlePathItemDTOList = this.comNameList
                }
                this.saveLoading = true
                this.$api.post(`sys/${this.type}`, form)
                    .then(res => {
                        if (res.code === 200) {
                            this.$store.commit('success', '添加成功')
                            this.form = {}
                        } else {
                            this.$store.commit('error', res.message)
                        }
                    })
                    .finally(() => {
                        this.saveLoading = false
                    })
            },
        },
        mounted() {
            document.getElementsByClassName('taoshu-body')[0].style.overflow = 'auto'
        },
        destroyed() {
            document.getElementsByClassName('taoshu-body')[0].style.overflow = ''
        },
    }
</script>